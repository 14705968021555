/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  NEW_COUNTER,
  UPDATE_COUNTER,
  GET_COUNTER,
  GET_COUNTERS,
  DELETE_COUNTER,
  DELETE_COUNTERS,
  GETWEBSOCKETURL
} from 'src/actions/counterAction';

const initialState = {
  counters: [],
  counter: null,
  presignUrl: null
};

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_COUNTER: {
        const { counter } = action.payload;

        return produce(state, (draft) => {
          draft.counters.push(counter);
        });
    }

    case UPDATE_COUNTER: {
      const { counter } = action.payload;

      return produce(state, (draft) => {
        draft.counters.map(_counter => {
            if(_counter._id === counter._id)
                return counter;
            return _counter;
        });
      });
    }

    case GET_COUNTER: {
      const { counter } = action.payload;

      return produce(state, (draft) => {
        draft.counter = counter;
      });
    }

    case GET_COUNTERS: {
      const { counters } = action.payload;

      return produce(state, (draft) => {
        draft.counters = counters;
      });
    }

    case DELETE_COUNTER: {
      const id = action.payload;
      return produce(state, (draft) => {
        draft.counters = draft.counters.filter(counter => counter._id !== id)
        draft.counter = null;
      });
    }

    case DELETE_COUNTERS: {
      return produce(state, (draft) => {
        draft.counters = [];
        draft.counter = null;
      });
    }
    case GETWEBSOCKETURL :{
      const { presignUrl } = action.payload
      return produce(state,(draft)=>{
        draft.presignUrl = presignUrl
      })
    }
    default: {
      return state;
    }
  }
};

export default counterReducer;
