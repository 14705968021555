/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  NEW_WAREHOUSE,
  UPDATE_WAREHOUSE,
  GET_WAREHOUSE,
  GET_WAREHOUSES,
  DELETE_WAREHOUSE,
  DELETE_WAREHOUSES,
  GET_WAREHOUSES_UPLOAD_CATALOG_PRESIGN_URL,
  WAREHOUSES_UPLOAD_CATALOG,
  GET_BATCH_STATUS,
  DELETE_CATALOG,
} from 'src/actions/warehouseAction';

const initialState = {
  warehouses: [],
  warehouse: null,
  presignUrl: null,
  submitCatalog: null,
  Status : null
};

const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_WAREHOUSE: {
      const { warehouse } = action.payload;

      return produce(state, (draft) => {
        draft.warehouses.push(warehouse);
      });
    }

    case UPDATE_WAREHOUSE: {
      const { warehouse } = action.payload;

      return produce(state, (draft) => {
        draft.warehouses.map(_warehouse => {
          if (_warehouse._id === warehouse._id)
            return warehouse;
          return _warehouse;
        });
      });
    }

    case GET_WAREHOUSE: {
      const { warehouse } = action.payload;

      return produce(state, (draft) => {
        draft.warehouse = warehouse;
      });
    }

    case GET_WAREHOUSES: {
      const { warehouses } = action.payload;

      return produce(state, (draft) => {
        draft.warehouses = warehouses;
      });
    }

    case DELETE_WAREHOUSE: {
      const id = action.payload;
      return produce(state, (draft) => {
        draft.warehouses = draft.warehouses.filter(warehouse => warehouse._id !== id)
        draft.warehouse = null;
      });
    }

    case DELETE_WAREHOUSES: {
      return produce(state, (draft) => {
        draft.warehouses = [];
        draft.warehouse = null;
      });
    }
    case GET_WAREHOUSES_UPLOAD_CATALOG_PRESIGN_URL: {
      const { presignUrl } = action.payload
      return produce(state, (draft) => {
        draft.presignUrl = presignUrl
      })
    }
    case WAREHOUSES_UPLOAD_CATALOG: {
      const {submitCatalog} = action.payload
      return produce(state,(draft)=>{
        draft.submitCatalog = submitCatalog
      })
    }
    case GET_BATCH_STATUS: {
      const {Status} = action.payload
      console.log(Status)
      return produce(state,(draft)=>{
        draft.Status = Status
      })
    }
    case DELETE_CATALOG: {
      const id = action.payload;
      return produce(state,(draft)=>{
        draft.id = id
      })
    }
    default: {
      return state;
    }
  }
};

export default warehouseReducer;
